/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for PrimeFlex grid system

/* PrimeNG */
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.scss';
@import 'primeicons/primeicons.css';
@import 'assets/layout/styles/theme/lara-light-indigo/theme.css';

/* Layout */
@import 'assets/layout/styles/layout/layout.scss';

/* AMN Styles */
@import 'assets/styles/amn_layout.scss';
