/* Fixes the p.divider not showing */
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid;
}
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid;
}

/* Fixes the p-avatar showing a background color */
.p-avatar-image {
  background-color: transparent;
}
/* Fixes the p-avatar circle shape */
.p-avatar-circle {
  border-radius: 50%;
}
