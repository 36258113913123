.customer-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin: 0 0.2rem;

  &.status-unknown {
    color: var(--blue-800);
    background: var(--blue-200);
  }
  &.status-user {
    color: var(--green-800);
    background: var(--green-200);
  }
  &.status-admin {
    color: var(--purple-800);
    background: var(--purple-200);
  }
  &.status-master {
    color: var(--orange-800);
    background: var(--orange-200);
  }
  &.status-financialadvisor {
    color: var(--indigo-800);
    background: var(--indigo-200);
  }
  &.status-empty {
    color: var(--bluegray-800);
    background: var(--bluegray-200);
  }
}

.contract-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-new {
    color: var(--blue-800);
    background: var(--blue-200);
  }
  &.status-draft {
    color: var(--bluegray-800);
    background: var(--bluegray-200);
  }
  &.status-senttosign {
    color: var(--indigo-800);
    background: var(--indigo-200);
  }
  &.status-signpending {
    color: var(--yellow-800);
    background: var(--yellow-200);
  }
  &.status-signed {
    color: var(--pink-800);
    background: var(--pink-200);
  }
  &.status-active {
    color: var(--teal-800);
    background: var(--teal-200);
  }
  &.status-completed {
    color: var(--green-800);
    background: var(--green-200);
  }
  &.status-cancelled {
    color: var(--red-800);
    background: var(--red-200);
  }
}

.contract-chip {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-new {
    color: var(--blue-800);
    background: var(--blue-200);
  }
  &.status-draft {
    color: var(--bluegray-800);
    background: var(--bluegray-200);
  }
  &.status-senttosign {
    color: var(--indigo-800);
    background: var(--indigo-200);
  }
  &.status-signpending {
    color: var(--yellow-800);
    background: var(--yellow-200);
  }
  &.status-signed {
    color: var(--pink-800);
    background: var(--pink-200);
  }
  &.status-active {
    color: var(--teal-800);
    background: var(--teal-200);
  }
  &.status-completed {
    color: var(--green-800);
    background: var(--green-200);
  }
  &.status-cancelled {
    color: var(--red-800);
    background: var(--red-200);
  }
}

.transaction-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.type-initial {
    color: var(--blue-800);
    background: var(--blue-200);
  }
  &.type-profit {
    color: var(--green-800);
    background: var(--green-200);
  }
  &.type-withdrawal {
    color: var(--bluegray-800);
    background: var(--bluegray-200);
  }
  &.type-deposit {
    color: var(--red-800);
    background: var(--red-200);
  }
  &.type-cancelprofit {
    color: var(--grey-800);
    background: var(--green-200);
  }
  &.type-penalty {
    color: var(--yelow-800);
    background: var(--bluegray-200);
  }
  &.type-settlement {
    color: var(--brown-800);
    background: var(--red-200);
  }
  &.type-reinvestment {
    color: var(--purple-800);
    background: var(--bluegray-200);
  }
  &.type-financialadvisorcommission {
    color: var(--orange-800);
    background: var(--red-200);
  }
  &.type-refund {
    color: var(--pink-800);
    background: var(--pink-200);
  }
}

// The following are just examples, when used let's change the names accordingly.

.product-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: var(--green-200);
    color: var(--green-800);
  }

  &.status-outofstock {
    background: var(--pink-200);
    color: var(--pink-800);
  }

  &.status-lowstock {
    background: var(--yellow-200);
    color: var(--yellow-800);
  }
}

.product-badge-text {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    color: var(--green-500);
  }

  &.status-outofstock {
    color: var(--pink-500);
  }

  &.status-lowstock {
    color: var(--yellow-500);
  }
}

.order-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.order-delivered {
    background: #c8e6c9;
    color: #256029;
  }

  &.order-cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.order-pending {
    background: #feedaf;
    color: #8a5340;
  }

  &.order-returned {
    background: #eccfff;
    color: #694382;
  }
}

.setting-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.category-global {
    color: var(--blue-800);
    background: var(--blue-200);
  }
  &.category-contract {
    color: var(--green-800);
    background: var(--green-200);
  }
  &.category-customer {
    color: var(--bluegray-800);
    background: var(--bluegray-200);
  }
}

.expense-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.category-food {
    color: #ffffff;
    background: #ff831e;
  }
  &.category-fiscal {
    color: #ffffff;
    background: #1e47ff;
  }
  &.category-general {
    color: #ffffff;
    background: #7f7f7f;
  }
  &.category-transportation {
    color: #ffffff;
    background: #cb3bff;
  }
  &.category-utilities {
    color: #ffffff;
    background: #89f96d;
  }
  &.category-technology {
    color: #ffffff;
    background: #0eefff;
  }
  &.category-contributor {
    color: #ffffff;
    background: #ff3575;
  }
  &.category-thirdparty {
    color: #ffffff;
    background: #413327;
  }
  &.category-client {
    color: #ffffff;
    background: #5ffcd5;
  }
  &.category-extra {
    color: #ffffff;
    background: #ffe138;
  }
}
