div.layout-sidebar {
  div.sidebar-header {
    a.app-logo {
      img.app-logo-normal {
        display: inline;
        height: 4rem;
        width: 15rem;
      }

      img.app-logo-small {
        display: none;
      }

      &:focus {
        @include focused();
      }
    }
  }
}
